<template>
    <Form
      @submit="forgotPassword"
      :validation-schema="schema"      
    >
      <div>
        <Field
          type="email"
          name="email"
          v-model="email"
          autocomplete="email"
          placeholder="luke@jedi.com"
          :class="inputClass"
          class="border-gray-500"
        />
        <ErrorMessage name="email" class="absolute text-sm text-red-600 inset-x-0 -bottom-5"/>
      </div>
      <div class="mt-6">
        <BaseBtn
          type="submit"
          text="Enviar Correo"
          class="py-2 px-4 text-center bg-blue-700 rounded-md w-full text-white text-xl hover:bg-blue-600"
        />
      </div>
    </Form>
</template>

<script>
import { getError } from "@/utils/helpers"
import { object, string } from "yup"
import { Field, Form, ErrorMessage } from "vee-validate"
import BaseBtn from "@/components/BaseBtn"
import AuthService from "@/services/AuthService"

export default {
  name: "ForgotPassword",
  setup() {
    const schema = object({
      email: string().required("El correo es un campo obligatorio.").email("Este no es un correo válido.")
    })
    return {
      schema,
      inputClass: "block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500"
    }
  },
  data() {
    return {
      email: "",
      error: null,
    };
  },
  methods: {
    forgotPassword() {
      this.error = null;
      this.message = null;
      const payload = {
        email: this.email,
      };
      AuthService.forgotPassword(payload)
        .then(() => {
          this.emitter.emit("notification-set", {
              type: 'success',
              title: "Correo enviado",
              text: "Se ha enviado un correo de confirmación para que puedas cambiar tu contraseña.",
              time: 10000,
              group: 'notifications'
            })
        })
        .catch((error) => (this.error = getError(error)));
    },
  },
  components: {
    Form,
    Field,
    BaseBtn,
    ErrorMessage,
  }
};
</script>